declare let $zoho: IZoho;
export interface ISalesIq {
    values: any;
    ready: any;
    afterReady?: any;
    reset?: any;
    visitor?: any;
    chat?: any;
    domain?: any;
}
export interface IZoho {
    salesiq: ISalesIq;
}

export class ZohoManager {
    domain: string = "turnstone.local";
    $zoho: any;
    isEnabled: boolean = false;
    constructor() {
        this.isEnabled = process.env.VUE_APP_ZOHO_CHAT_ENABLED === "true";
        // @ts-ignore
        window["$zoho"] = window["$zoho"] || {};
        // @ts-ignore
        window["$zoho"].salesiq = {
            values: {}, reset: () => { }, ready: () => { },
            widgetcode: "a2d77cf44d1ea3ebb7157bcdc29a29b8fe0688520304bfb18b340" +
                "66fb2e08a5bd8c8c22e46eb4d9a02278a35ca57b4d3feb79731b60e202192c2895c9acefb61",
        };
        // @ts-ignore
        this.$zoho = window["$zoho"];
    }
    init(email: string) {
        if (!this.isEnabled) { return; }
        try {

            const d = document;
            const s = d.createElement("script");
            s.type = "text/javascript";
            s.id = "zsiqscript";
            s.defer = true;
            s.src = "https://salesiq.zoho.com/widget";
            const t = d.getElementsByTagName("script")[0];
            if (t.parentNode != null) {
                t.parentNode.insertBefore(s, t);
            }
            $zoho.salesiq.ready = () => {
                if (!$zoho) { return; }
                $zoho.salesiq.visitor.email(email);
            };
        } catch (err) {
        }
    }
    UpdateUser(email: string) {
        if (!this.isEnabled) { return; }
        try {
            if (email === "") {

                // how did I get here:
                // the email setter source:
                /*
                    (t){
                        if( null!=t
                            &&
                            /^([\w]([\w\-\.\+\'\/]*)@([\w\-\.]*)(\.[a-zA-Z]{2,22}(\.[a-zA-Z]{2}){0,2}))$/.test(t)
                            ){
                    try{
                        $UTS.DB.store("siq_email",t,6e4)
                    }catch(t){}
                    return $zohosq.setValue("email",t,3),$zv.email}}
                */

                // skipping the regex to avoid not emails to be used
                // setting the email to empty string by calling the "real"
                // setter
                // the last parameter (3) is used in the notifyTracking method
                // to define the correct switch/case to use
                //
                // @ts-ignore
                window["$zohosq"].setValue("email", email, 3);
            } else {
                $zoho.salesiq.visitor.email(email);
                $zoho.salesiq.ready = () => {
                    $zoho.salesiq.visitor.email(email);
                };
            }
        } catch { }
    }
}

export const zohoManager = new ZohoManager();