import Vue from "vue";
import Router, { RawLocation, RouteRecord } from "vue-router";

import { dashboardRoutes } from "@/modules/dashboard/routes";
import { authRoutes } from "@/modules/auth/routes";
import { routes as companyRoutes } from "@/modules/company";
import { searchRoutes, companySearchRoutes } from "@/modules/search/routes";
import { routes as projectRoutes } from "@/modules/project/routes";
import { routes as alertRoutes } from "@/modules/alerts/routes";
import { routes as subscriptionRoutes } from "@/modules/subscriptions/routes";
import { routes as exportRoutes } from "@/modules/export/routes";
import { trackingRoutes } from "@/modules/tracking/routes";
import { accountRoutes } from "@/modules/account/routes";
import { routeStack } from "./core/services/routeStack.service";
import { adminRoutes } from "@/modules/administration/routes";
import { routes as tagRoutes } from "@/modules/tags/routes";
import { AccountService, AuthTokenService } from "./core/services";
import { EmbedView, WordpressEmbedCpt } from "./modules/integration";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    ...dashboardRoutes,
    ...searchRoutes,
    ...tagRoutes,
    ...authRoutes,
    ...projectRoutes,
    ...alertRoutes,
    ...subscriptionRoutes,
    ...companyRoutes,
    ...companySearchRoutes,
    ...exportRoutes,
    ...trackingRoutes,
    ...accountRoutes,
    ...adminRoutes,
    {
      path: "/help",
      name: "Help",
      meta: { layout: "basic" },
      component: EmbedView,
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const userStatus = {
    allowAnonymous: to.matched.some((record: RouteRecord) => record.meta.allowAnonymous),
    isAuthenticated: router.app.$auth.isAuthenticated(),
    machineCode: await AccountService.getMachineCode(),
  };
  if (userStatus.isAuthenticated && !userStatus.machineCode && to.name !== "overlapping-login") {
    next({
      name: "overlapping-login",
    });
    return;
  }

  if (!userStatus.allowAnonymous && !userStatus.isAuthenticated) {
    // If a non-anonymoyus route and user not authenticated
    // TRY to refresh the token at least once. If no refresh token is available.
    // the refreshToken could throw an error, so we catch it and we redirect to login.
    try {
      const response = await AccountService.refreshToken();
      userStatus.machineCode = await AccountService.getMachineCode();
      if (!!response) {
        routeStack.push(from);
        // keep track of the redirect
        next({
          query: {
            redirectTo: to.path,
          },
        });
        return;
      }
    } catch {
      next({
        path: "/login",
        query: {
          redirectTo: to.path,
        },
      });
    }
    return;
  }

  if (userStatus.allowAnonymous && userStatus.isAuthenticated) {
    // Exception for social media page
    if (to.name === "SocialMediaLandingPage") {
      // TODO redirect to project page
      next();
      return;
    }

    if (to.name === "loginCis") {
      next();
      return;
    }

    // if anonymous route, and user authenticated, redirect to home, don't want to bother the user
    next("/dashboard");
    return;
  }

  routeStack.push(from);
  next();
});

export default router;
